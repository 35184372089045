<template>
  <div
    class="card"
    style="border: none"
    id="print-lab-report"
  >
    <div class="card-body">
      <div class="d-flex justify-content-end">
        <img
          src="/images/form/logo-yayasan-hayandra.jpg"
          width="200"
        />
      </div>
      <div class="text-center">
        <h4 style="font-weight: bold; ext-transform: uppercase; font-size: 14px">
          <u>PURCHASE ORDER</u>
          <!-- <hr
            style="
              border-bottom: 2px solid black;
              margin-right: 200px;
              margin-left: 200px;
              margin-top: 1px;
              margin-bottom: 5px;
            "
          /> -->
        </h4>
      </div>
      <div class="d-flex justify-content-end">
        <span
          style="font-size: 12px; font-weight: light"
          class="mr-25"
        >Jakarta, {{ data.date }}</span>
      </div>
      <div class="d-flex-justify-content-start">
        <div class="col pt-0">
          <h4 style="font-weight: bold; ext-transform: uppercase; font-size: 12px">
            Purchase Order No <span class="px-1">:</span> {{ data.po_no }}
          </h4>
        </div>
      </div>
      <div class="d-flex-justify-content-start">
        <div class="col pt-0">
          <span style="font-size: 12px">Kepada Yth,</span>
          <h5 style="font-size: 13px; font-weight: bold">
            {{ data.vendor_name }}
          </h5>
        </div>
      </div>
      <div class="d-flex justify-content-start">
        <div class="col pt-0">
          <span style="font-size: 12px">Hal <span class="ml-2 mr-1">:</span> Purchase Order ( PO )</span>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-2">
        <div class="col pt-0">
          <span style="font-size: 12px">Dengan Hormat,</span><br />
          <!-- <span style="font-size: 12px"
            >Sehubungan dengan surat penawaran harga (quotation)
            <b>{{ data.vendor_name }}</b>
            , maka kami ingin memesan produk sebagai berikut :
          </span> -->
          <span style="font-size: 12px">
            {{data.opening_sentence}}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col px-7">
          <table
            class="table table-bordered my-table"
            style="font-size: 12px"
          >
            <tr>
              <th class="p-2">No</th>
              <th class="p-2">Items</th>
              <th class="p-2">Quantity</th>
              <th class="p-2">Price/case(IDR)</th>
              <th class="p-2">Total Price</th>
            </tr>
            <tr
              v-for="(item, index) in data.items_display"
              :key="index"
            >
              <td class="p-2">{{ index + 1 }}</td>
              <td class="p-2">{{ item.item }}</td>
              <td class="p-2">{{ item.quantity }}</td>
              <td class="p-2 text-right">{{ item.price }}</td>
              <td class="p-2 text-right">{{ item.total_price }}</td>
            </tr>
            <tr>
              <td class="p-2"></td>
              <td class="text-center p-2">
                <span style="font-weight: bold">
                  SUB TOTAL 1
                </span>
              </td>
              <td class="p-2"></td>
              <td class="p-2"></td>
              <td class="p-2 text-right">{{parseInt(subTotal).toLocaleString("id-ID")}}</td>
            </tr>
            <tr>
              <td class="p-2"></td>
              <td class="text-center p-2">
                <span style="font-weight: bold">
                  Diskon {{data.discount}}%
                </span>
              </td>
              <td class="p-2"></td>
              <td class="p-2"></td>
              <td class="p-2 text-right" v-if="data.discount != 0">{{parseInt(subTotal * data.discount / 100).toLocaleString("id-ID")}}</td>
              <td class="p-2 text-right" v-if="data.discount == 0">{{parseInt(subTotal).toLocaleString("id-ID")}}</td>
            </tr>
            <tr>
              <td class="p-2"></td>
              <td class="text-center p-2">
                <span style="font-weight: bold">
                  SUB TOTAL 2
                </span>
              </td>
              <td class="p-2"></td>
              <td class="p-2"></td>
              <td class="p-2 text-right">{{parseInt(subTotal2).toLocaleString("id-ID")}}</td>
            </tr>
            <tr>
              <td class="p-2"></td>
              <td class="text-center p-2">
                <span style="font-weight: bold" class="p-2">
                  PPn {{data.ppn}}%
                </span>
              </td>
              <td class="p-2"></td>
              <td class="p-2"></td>
              <td class="p-2 text-right" v-if="data.ppn != 0">{{parseInt(subTotal2 * data.ppn / 100).toLocaleString("id-ID")}}</td>
              <td class="p-2 text-right" v-if="data.ppn == 0">{{parseInt(subTotal2).toLocaleString("id-ID")}}</td>
            </tr>
            <tr>
              <td class="p-2"></td>
              <td class="text-center p-2">
                <span style="font-weight: bold">
                  TOTAL
                </span>
              </td>
              <td class="p-2"></td>
              <td class="p-2"></td>
              <td class="p-2 text-right">{{parseInt((subTotal2 * data.ppn / 100) + subTotal2).toLocaleString("id-ID")}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="d-flex justify-content-start mt-2">
        <div class="col">
          <!-- <span style="font-size: 12px">
            Demikian pesanan ini kami sampaikan, Mohon kiranya dapat di proses
            dengan segera.Atas perhatian dan kerjasamanya kami ucapkan terima
            kasih </span> -->
          <span style="font-size: 12px">
            {{data.closing_sentence}}
          </span>
          <span style="font-size: 12px">
            Demikian pesanan ini kami sampaikan, Mohon kiranya dapat di proses
            dengan segera.Atas perhatian dan kerjasamanya kami ucapkan terima
            kasih </span>
          <br />
          <span style="font-size: 12px">Hormat kami,</span><br />
          <img
            src="/images/form/ttd.jpg"
            height="55"
            class="ml-2"
          />
          <h5 style="font-size: 12px; font-weight: bold">
            <u>dr. Karina F. Moegni,Sp.BP-RE</u>
          </h5>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-2">
        <div class="col">
          <h5 style="font-size: 12px; font-weight: bold">
            <u>Delivery address:</u>
          </h5>
          <table class="table table-borderless" style="font-size: 12px">
            <tr>
              <td><span>Nama Pemesan</span></td>
              <td>
                : <span style="font-size: 12px; font-weight: bold"> <u>dr.Karina F. Moegni,Sp.BP-RE</u></span>
              </td>
            </tr>
            <tr>
              <td><span>Alamat Kirim</span></td>
              <td>
                : <span style="font-size: 12px; font-weight: bold"> <u>Yayasan Hayandra Peduli</u></span><br>
                <span>Jl. Kramat 6 No 11.Jakarta Pusat 10430</span>
              </td>
            </tr>
            <tr>
              <td><span>Kota</span></td>
              <td>
                <span>: Jakarta Pusat</span>
              </td>
            </tr>
            <tr>
              <td><span>No Telp/HP</span></td>
              <td>
                <span>: (021)3909333 / 0811817490</span>
              </td>
            </tr>
            <tr>
              <td><span>CP</span></td>
              <td>
                <span>: Khodizah (082114672119)</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    setting: Object,
  },

  data() {
    return {
      subTotal2: 5400000
    }
  },

  methods: {
    btnDownloadOnClick() {
      this.$refs.html2Pdf.generatePdf();
    },

    btnPrintOnClick() {
      this.$htmlToPaper("print-lab-report");
    },

    onProgress($event) {
      console.log("onProgress", $event);
    },

    hasStartedGeneration() {
      console.log("hasStartedGeneration");
    },

    hasGenerated($event) {
      console.log("hasGenerated", $event);
    },
  },
  computed: {
    // totalHarga() {
    //   return this.keranjangs.reduce(function (items, data) {
    //     return items + data.products.harga * data.jumlah_pemesanan;
    //   }, 0);
    // },
    subTotal() {
      return this.data.items_display.reduce(function (items, data) {
        // console.log("items", items) 
        // console.log("data", data.total_price) 
        let total_price = data.total_price.replace(".", "")
        let count = items + parseInt(total_price)
        let count_replace = parseInt(count).toLocaleString("id-ID")
        return items + parseInt(total_price)
      }, 0)
    }
  }
};
</script>

<style scoped>
/* table.table-bordered {
  border: 1px solid black !important;
  margin-top: 20px;
}
table.table-bordered > thead > tr > th {
  border: 1px solid black !important;
}
table.table-bordered > tbody > tr > td {
  border: 1px solid black !important;
} */
/* .my-table>tbody>tr>td, .mytable>tbody>tr>th, .mytable>tfoot>tr>td, .mytable>tfoot>tr>th, .mytable>thead>tr>td, .mytable>thead>tr>th {
    padding: 12px;
} */
</style>